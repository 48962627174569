@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap');

@import 'variables';
@import 'base';
@import 'navbar';
@import 'hero';
@import 'about';
@import 'skills';
@import 'projects';
@import 'contact';
@import 'footer';
